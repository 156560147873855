/* eslint-disable no-useless-escape */
var isNull = function (objectArg) {
    /// <summary>
    /// If passed argument is null OR undefined then return value is TRUE, otherwise is FALSE
    /// </summary>
    var checkFlag = false;
    if (objectArg === null) {
      checkFlag = true;
    }
    return checkFlag;
  };
  
  var isNumber = function (input) {
    /// <summary>Funkcija provjerava je li proslijeđena vrijednost broj: vraća true ako je input broj i ako je input konačan broj
    /// </summary>
    /// <param name="input" type="string">Unesena vrijednost</param>
    if (typeof input == "string" || input instanceof String)
      input = input.replace(",", ".");
    return !isNaN(parseFloat(input)) && isFinite(input);
  };
  
  var isInt = function (n) {
    return Number(n) === n && n % 1 === 0;
  };
  
  var isFloat = function (n) {
    return Number(n) === n && n % 1 !== 0;
  };
  
  var isNullOrWhitespace = function (input) {
    /// <summary>Funkcija provjerava je li proslijedjena vrijednost null, undefined ili se sastoji samo od razmaka/praznina (whitespace)
    /// </summary>
    /// <param name="input" type="string">Vrijednost za provjeru</param>
    if (typeof input === "boolean") return false;
    if (typeof input === "undefined" || input == null) return true;
    if (typeof input === "object") return false;
    if (isNumber(input)) return false;
    return input.replace(/\s/g, "").length < 1;
  };
  
  var isNullOrUndefined = function (input) {
    if (typeof input === "undefined" || input == null) {
      return true;
    }
    return false;
  };
  
  var isArray = function (input) {
    if (input instanceof Array) return true;
    return false;
  };
  
  var isSBoolean = function (input) {
    if (isNullOrWhitespace(input)) return false;
    if (
      (typeof input == "string" || input instanceof String) &&
      (input.toLowerCase() === "true" || input.toLowerCase() === "false")
    )
      return true;
  
    return false;
  };
  
  var isBoolean = function (input) {
    if (typeof input === "boolean") return true;
    return false;
  };
  
  var isHexColor = function (input) {
    ///^#[0-9a-f]{3,6}$/i
    var isOk = /(^#[0-9A-F]{6}$)|(^#[0-9A-F]{3}$)/i.test(input);
    return isOk;
  };
  
  var isAlignement = function (input) {
    if (
      (typeof input == "string" || input instanceof String) &&
      (input === "left" || input === "right" || input === "center")
    )
      return true;
  
    return false;
  };
  
  var checkIsIPV4 = function (entry) {
    try {
      var blocks = entry.split(".");
      if (blocks.length === 4) {
        return blocks.every(function (block) {
          return (
            !isNaN(block) &&
            parseInt(block, 10) >= 0 &&
            parseInt(block, 10) <= 255
          );
        });
      }
    } catch (lEx) {}
  
    return false;
  };
  
  var isMailValid = function (mail) {
    /// <summary>Funkcija za validaciju korisnicke mail adrese.
    /// </summary>
    /// <param name="mail" type="string">Unesena mail adresa</param>
  
    if (isNullOrWhitespace(mail)) return false;
    var mailRegex = /^[a-zA-Z][\w\.-]*[a-zA-Z0-9]@[a-zA-Z0-9][\w\.-]*[a-zA-Z0-9]\.[a-zA-Z][a-zA-Z\.]*[a-zA-Z]$/;
    return mailRegex.test(mail);
  };
  
  var isValidURL = function (str) {
    var pattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // fragment locator
    return !!pattern.test(str);
  };
  
  export {
    isNull,
    isNumber,
    isInt,
    isFloat,
    isNullOrWhitespace,
    isNullOrUndefined,
    isArray,
    isSBoolean,
    isBoolean,
    isHexColor,
    isAlignement,
    checkIsIPV4,
    isMailValid,
    isValidURL,
  };
  