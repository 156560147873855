import React, {useState, useCallback} from 'react'
import { getVmBaseUrl } from '../api/apiHelper'

export const useHttp = () => {

    const [isFetching, setIsFetching] = useState(false)
    const [errorMsg, setErrorMsg] = useState(null)

    const fetchData = useCallback(async (requestConfig, requestData) => {

        setIsFetching(true)
        setErrorMsg(null)

        try{
            const response = await fetch(requestConfig.url, {
              method: requestConfig.method ? requestConfig.method : "GET",
              headers: requestConfig.headers
                ? requestConfig.headers
                : { Authorization: `Basic ${btoa("m-events:kims")}` },
              body:requestConfig.body ? JSON.stringify(requestConfig.body):null
            });

            if(!response.ok) {
                throw new Error("Request Failed!")
            }

            const data = await response.json();
            requestData(data)

        }catch (err){
            setErrorMsg(err.message || "Something went wrong");

        }
        setIsFetching(false)
    }, [])

    return {
        isFetching,
        errorMsg,
        fetchData
    }

}

export const usePost = ({evnetId, token}) => {

    const [userData, setUserData] = useState({})

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        event_id: 125,
        jwt_token:
          "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiI3Nzg3OGMzYS1mY2FmLTRmNjAtOTZiOS0wOGUwMWI5MzVjODgiLCJ1bmlxdWVfbmFtZSI6IjEwMDc2ODIiLCJqdGkiOiJmMDBhMjI5OC05OTJiLTRiNWMtYTI2MS0zY2U5MWZmNmEyODIiLCJuYmYiOjE2NTIyNjI3MTMsImV4cCI6MTY1MjY5NDcxMywiaXNzIjoiSk1hcnF1YXJkdCBUZWNobm9sb2dpZXMgR21iSCIsImF1ZCI6Ind3dy5tLWFuYWdlLmNvbSJ9.PM2fEnNXUcJk4EQeKgwIy_c9a9MaotuSFtg4Wb3bMIg",
        include_user:1,
        include_grants:0
      })};

      fetch(`https://${getVmBaseUrl()}/v0/logins/jwt.json`, requestOptions)
      .then(response => response.json())
      .then(data => setUserData(data))

      return userData
    };


