import React, { useEffect, useState, useContext } from "react";
import QnAContext from "../../context/QnAContext";
import { getCurrentQs } from "../../utils/Helper";
import { deflate64 } from "../../utils/PakoHelper";

import routes from "./../../constants/routes.json";
import { get as getStorageValue } from "./../../utils/LocalStorage";
import { getEventId } from "../../utils/QueryString";

const Login = ({ encryptMode }) => {
  const [setting, setSetting] = useState(true);
  const [returnUrl, setReturnUrl] = useState();
  const { loginSettings } = useContext(QnAContext);

  useEffect(() => {
    if (encryptMode) {
      setReturnUrl(
        `${routes.SESSIONS}/${deflate64(
          String(getStorageValue("last.sessionId"))
        )}${getCurrentQs()}`
      );
    } else {
      setReturnUrl(
        `${routes.SESSIONS}/${getStorageValue(
          "last.sessionId"
        )}${getCurrentQs()}`
      );
    }

    setSetting(false);
  }, []);

  // if(encryptMode){

  // }else{
  //   const returnUrl = `/session?session_id=${items.current_session_id}&event_id=${items.current_event_id}`; // --- Return URL after the login was successful
  // }

  if (setting) {
    return <div>Loading...</div>;
  }

  return (
    <div
      id={loginSettings.id}
      identity_url={loginSettings.identityUrl}
      oauth_url={loginSettings.oauthUrl}
      client_id={loginSettings.client_id}
      api_url={loginSettings.apiUrl}
      instance={`${getEventId()}.qna`}
      language={loginSettings.language}
      event_short={loginSettings.short}
      return_url={returnUrl}
      context={loginSettings.context}
    ></div>
  );
};

export default Login;
