const store = function (key, objectValue) {
  /// <summary>
  /// Cache some pages in session storage, for now just #bets, in the future maybe: #login,#information etc.
  /// </summary>
  /// <param name="key" type="string">key under session object is stored</param>
  /// <param name="objectValue" type="object">objectValue </param>
  if (typeof localStorage !== "undefined") {
    if (key !== null) {
      if (localStorage.key) {
        try {
          //sessionStorage.key = objectValue;
          localStorage.setItem(key, JSON.stringify(objectValue));
          //console.log('stored!');
        } catch (e) {
          console.log("LIMIT REACHED: ");
          console.log(e);
        }
      }
    }
  } else {
    console.log("session storage NOT supported!!");
  }
};

const remove = function (key) {
  /// <summary>
  /// Clear all data in session storage
  /// </summary>
  if (typeof localStorage !== "undefined") {
    localStorage.removeItem(key);
  }
};

const clear = function () {
  /// <summary>
  /// Clear all data in session storage
  /// </summary>
  if (typeof localStorage !== "undefined") {
    localStorage.clear();
  }
};

const get = function (key) {
  /// <summary>
  /// Get object in storage by key, if there is no key value return NULL
  /// </summary>
  var retObjectValue = null;
  if (typeof localStorage !== "undefined") {
    if (localStorage.key) {
      retObjectValue = localStorage.getItem(key);
    }
  }

  return JSON.parse(retObjectValue);
};

// public API
export { store, remove, clear, get };
