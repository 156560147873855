import React from "react";

export const Modal = (props) => {
  return (
    <div className="modal" style={{ display: "block", top: "30%" }}>
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">{props.text.title}</h5>
            <button
              type="button"
              className="btn-close btn-light btn-md"
              aria-label="Close"
              onClick={() => props.setLoginModalOpen(false)}
              style={{ border: "none" }}
            >
              X
            </button>
          </div>
          <div className="modal-body">
            <p>{`${props.text.content}`}</p>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
              onClick={() => props.setLoginModalOpen(false)}
            >
              {props.text.button1}
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => props.action()}
            >
              {props.text.button2}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
