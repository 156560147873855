import React, { useState, useEffect } from "react";
import { StreamPlayerContainer } from "react-vm-component-library";

const StreamContent = ({
  txtOpenStream,
  txtCloseStream,
  playerConfig,
  ...rest
}) => {
  const [streamOpened, setStreamOpened] = useState(false);
  useEffect(()=>{
      console.log(playerConfig?.streamUrl)
  },[playerConfig?.streamUrl]);
  let btnClasses = ["toogle-btn"];
  if(streamOpened)
    btnClasses.push("active");
    if(!playerConfig?.streamUrl)
    btnClasses.push("jme-qna-disabled");
  return (
    <div className="qna-at-stream-content">
      <div className="open-toogle">
        <div
          className={`${btnClasses.join(' ')}`}
          onClick={() => setStreamOpened((prev) => !prev)}
        >
          {streamOpened ? txtCloseStream : txtOpenStream}{" "}
          <i className="fas fa-headphones-alt"></i>
        </div>
      </div>

      <div className="player-container">
        {streamOpened && playerConfig?.streamUrl && <StreamPlayerContainer {...playerConfig} />}
      </div>
    </div>
  );
};

export default StreamContent;
