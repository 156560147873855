import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import {QnAProvider} from "./context/QnAContext"

// const instance = createInstance({
//   urlBase: 'https://stats.virtual-meeting.net',
//   siteId: 45,
//   trackerUrl: 'https://stats.virtual-meeting.net/matomo.php', // optional, default value: `${urlBase}matomo.php`
//   srcUrl: 'https://stats.virtual-meeting.net/matomo.js',
//   configurations: { 
//     disableCookies: true
//   }
// })

ReactDOM.render(
  <BrowserRouter>
    <React.StrictMode>
    {/* <MatomoProvider value={instance}> */}
      <QnAProvider>
        <App />
      </QnAProvider>
      {/* </MatomoProvider> */}
    </React.StrictMode>
  </BrowserRouter>,
  document.getElementById("root")
);

